<template>
  <b-modal
    id="delete-user-modal"
    :title="$t('about-nectios.delete-nectios.delete')"
    size="lg"
    hide-footer
  >
    <feather-icon
      size="30"
      icon="AlertTriangleIcon"
      class="d-block mx-auto text-danger mb-1"
    />
    <h5 class="mb-2 px-2 text-center">
      {{ $t("about-nectios.delete-nectios.title") }}
    </h5>
    <div class="d-flex justify-content-center mb-2 flex-wrap">
      <b-button
        variant="primary"
        target="_blank"
        class="mt-25"
        @click="closeModal"
      >
        {{ $t("unshare.options.no") }}
      </b-button>
      <b-button
        variant="outline-danger"
        target="_blank"
        class="ml-md-1 mt-25"
        @click="removeCommunity"
      >
        {{ $t("unshare.options.yes") }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import Service from "@/config/service-identifiers";

export default {
  name: "DeleteUser",
  methods: {
    async removeCommunity() {
      await this.$store.$service[Service.BackendClient].post("user/delete", {
        userKey: this.loggedUser.key,
      });
      this.$store.dispatch("logout");
      window.location.href = process.env.VUE_APP_LANDING_URL;
    },
    closeModal() {
      this.$bvModal.hide("delete-user-modal");
    },
  },
};
</script>

<style></style>
